import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import marker from '../images/marker.svg'

import SEOPage from '../components/SEO/Page'

class DirectionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mapIsReady: false,
    }
  }

  componentDidMount() {
    const script = document.createElement('script')
    const API = 'AIzaSyDGc009Ay9ZcUiu8PKwJN7ZFiaxS5PFIDs'
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API}`
    script.async = true
    script.addEventListener('load', () => {
      this.setState({ mapIsReady: true })
    })
    document.body.appendChild(script)
  }

  render() {
    const { location, data } = this.props
    const directionContent = data.prismicDirections.data
    const settingsContent = data.prismicSettings.data

    if (this.state.mapIsReady) {
      this.map = new window.google.maps.Map(this.refs.map, {
        center: {
          lat: directionContent.map_coordinates.latitude,
          lng: directionContent.map_coordinates.longitude,
        },
        zoom: 16.5,
        disableDefaultUI: true,
        gestureHandling: 'cooperative',
        styles: [
          {
            featureType: 'administrative',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
              {
                hue: '#ffbb00',
              },
              {
                saturation: 43.400000000000006,
              },
              {
                lightness: 37.599999999999994,
              },
              {
                gamma: 1,
              },
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry.fill',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [
              {
                visibility: 'on',
              },
              {
                color: '#f8f8f8',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              {
                hue: '#00ff6a',
              },
              {
                saturation: -1.0989010989011234,
              },
              {
                lightness: 11.200000000000017,
              },
              {
                gamma: 1,
              },
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
              {
                saturation: -61.8,
              },
              {
                lightness: 45.599999999999994,
              },
              {
                gamma: 1,
              },
              {
                visibility: 'simplified',
              },
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'all',
            stylers: [
              {
                hue: '#ff0300',
              },
              {
                saturation: -100,
              },
              {
                lightness: 51.19999999999999,
              },
              {
                gamma: 1,
              },
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'all',
            stylers: [
              {
                hue: '#FF0300',
              },
              {
                saturation: -100,
              },
              {
                lightness: 52,
              },
              {
                gamma: 1,
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              {
                hue: '#0078FF',
              },
              {
                saturation: -13.200000000000003,
              },
              {
                lightness: 2.4000000000000057,
              },
              {
                gamma: 1,
              },
            ],
          },
        ],
      })

      var mapMarker = {
        url: marker,
        scaledSize: new window.google.maps.Size(40, 40),
      }

      var _this = this
      _this.marker = new window.google.maps.Marker({
        position: {
          lat: directionContent.map_coordinates.latitude,
          lng: directionContent.map_coordinates.longitude,
        },
        map: _this.map,
        icon: mapMarker,
        optimized: false,
      })
    }
    return (
      <Layout>
        <SEOPage
          title={directionContent.title && directionContent.title}
          location={location}
        />
        <div className="ratio ratio-5/12 mb-3 lg:mb-8 h-131 lg:h-0">
          <div ref="map" className="absolute pin-t pin-l w-full h-full" />
        </div>
        {directionContent.map_link.url && (
          <div className="px-3 lg:px-14">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={directionContent.map_link.url}
              className="link underline text-sm"
            >
              Open in Google Maps
            </a>
          </div>
        )}

        <div className="lg:flex mt-16 lg:mt-32 px-3 lg:px-14 mb-12">
          {directionContent.title && (
            <div className="lg:w-1/3">
              <h1 className="mb-12 lg:mb-20 lg:w-4/5 text-4xl font-light leading-tight text-grey">
                {directionContent.title}
              </h1>
            </div>
          )}

          <div className="lg:w-2/3 text-lg leading-lg">
            <div className="lg:flex">
              <div className="lg:w-3/4">
                <div className="text-2xl">
                  {directionContent.contact_title_primary && (
                    <p className="mb-0">
                      {directionContent.contact_title_primary}
                    </p>
                  )}
                  {settingsContent.physical_address.html && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: settingsContent.physical_address.html,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="lg:w-1/4" />
              <div className="hidden lg:block lg:w-3/4">
                {directionContent.contact_title_secondary && (
                  <p className="text-2xl">
                    {directionContent.contact_title_secondary}
                  </p>
                )}
              </div>
              <div className="lg:w-1/4" />
            </div>
          </div>
        </div>
        <div className="lg:flex mb-16 lg:mb-48 lg:pb-8 px-3 lg:px-14 text-lg leading-lg">
          <div className="lg:w-1/3" />

          <div className="lg:w-2/3 text-lg leading-lg">
            <div className="lg:flex border-b border-grey-light mb-16 lg:mb-35">
              <div className="lg:w-3/4">
                <div className="mb-4">
                  {settingsContent.phone_number && (
                    <div>
                      <span>p. </span>
                      <a
                        className="link inline-block"
                        href={`tel:${settingsContent.phone_number}`}
                      >
                        +64 {settingsContent.phone_number}
                      </a>
                    </div>
                  )}
                  {settingsContent.fax_number && (
                    <div>
                      <span>f. </span>
                      <a
                        className="link inline-block"
                        href={`tel:${settingsContent.fax_number}`}
                      >
                        +64 {settingsContent.fax_number}
                      </a>
                    </div>
                  )}
                  {settingsContent.email_address && (
                    <div>
                      <span>e. </span>
                      <a
                        className="link inline-block underline font-medium"
                        href={`mailto:${settingsContent.email_address}`}
                      >
                        {settingsContent.email_address}
                      </a>
                    </div>
                  )}
                </div>

                <div className="mb-16 lg:mb-35">
                  {settingsContent.postal_address_title && (
                    <p className="font-medium">
                      {settingsContent.postal_address_title}
                    </p>
                  )}
                  {settingsContent.postal_address.html && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: settingsContent.postal_address.html,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="lg:w-1/4" />
              <div className="lg:w-3/4">
                {directionContent.contact_title_secondary && (
                  <div className="lg:hidden border-t border-grey-light">
                    <p className="text-2xl mt-16 mb-16">
                      {directionContent.contact_title_secondary}
                    </p>
                  </div>
                )}
                {directionContent.contact_text.html && (
                  <div
                    className="mb-4 rte"
                    dangerouslySetInnerHTML={{
                      __html: directionContent.contact_text.html,
                    }}
                  />
                )}
                {directionContent.buses.length && (
                  <div className="mb-16">
                    {directionContent.buses.map((item, i) => {
                      return (
                        <div key={i}>
                          <span className="inline-block w-12 font-medium">
                            {item.bus_number}{' '}
                          </span>
                          <p className="inline-block">{item.bus_route}</p>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
              <div className="lg:w-1/4" />
            </div>
            {directionContent.text.html && (
              <div
                className="text-2xl rte"
                dangerouslySetInnerHTML={{
                  __html: directionContent.text.html,
                }}
              />
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageDirectionsQuery = graphql`
  query {
    prismicDirections {
      data {
        map_coordinates {
          latitude
          longitude
        }
        map_link {
          url
        }
        title
        contact_title_primary
        contact_title_secondary
        contact_text {
          html
        }
        buses {
          bus_number
          bus_route
        }
        text {
          html
        }
      }
    }
    prismicSettings {
      data {
        phone_number
        fax_number
        email_address
        physical_address_title
        physical_address {
          html
        }
        postal_address_title
        postal_address {
          html
        }
      }
    }
  }
`

export default DirectionsPage
